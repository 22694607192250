@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&&family=Poppins:wght@400;500;600&display=swap');

$primary-color: #772CF3;
$primary-light-color: #A067FE;
$primary-dark-color: #31346A;
$gray: #656565;
$gray-light: #D7D7D7;
$gray-dark: #404040;
$white: #FFFFFF;
$white-dark: #F9F9F9;
$black: #000000;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  word-wrap: normal;
}

a, button {
  color: $black;
}

.input-date-naissance {
  width: 100%;
}

button {
  transition: transform 0.3s ease-in-out;
  
  &:hover {
    transform: translateY(-5px);
  }
}

footer {
  display: block;
  text-align: center;
  position: relative;
  margin-top: 75px;
}

table {
  display: table;
  width: 100%;
  overflow-x: auto;
}

@media screen and (max-width: 40em){
  .responsiveTable tbody tr {
    border: 1.2px solid $gray-light !important;
    margin-bottom: 5px;
  }

  .responsiveTable thead {
    display: none !important;
  }
}

input, select {
  transition: transform 0.3s ease-in-out;
  
  &:hover {
    transform: translateY(-2px);
  }
}


.navLink {
  display: flex;
  gap: 65px;
  margin: 75px 0;
  font-size: 1.875rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  
  & > div {
    padding-bottom: 19px;

    &.selected {
      border-bottom: 4px solid #8ac923;
    }
  } 
}


#split-window {
  position: relative;
  display: flex;
  min-height: calc(100vh - 130px);
  width: 100%;
}

header {
  z-index: 24;
  
  &.align-left {
    justify-content: flex-start !important;
    gap: 50px;
  }

  h2 {
    color: $primary-dark-color;
    font-family: 'Poppins', sans-serif;
    font-size: calc(1.1rem + 0.4vw);
    font-weight: 500;
    text-align: center;
  }

  &.header {
    width: 100%;
    padding: 0 60px;
    border-bottom: 1.2px solid $gray-light;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .burger-menu {
      display: none;
      cursor: pointer;
      height: 25px;
      width: 25px;
    }

    .logo {
      margin-left: calc(112px - 60px);
      padding: 45px 92px 45px 0;
      border-right: 1.2px solid $gray-light;
    }

    .menu {
      list-style: none;
      display: flex;
      align-items: center;
      gap: 20px;

      .choice {
        display: flex;
        align-items: center;
        gap: 5px;
        border-radius: 300px;
        padding: 5px 10px;
        border: 1px solid $gray;
        
        .arrow {
          display: none;
        }

        label.online {
          height: 10px;
          width: 10px;
          border-radius: 5px;
          background-color: #47b747;
        }

        label.offline {
          height: 10px;
          width: 10px;
          border-radius: 5px;
          background-color: #F20000;
        }
      }

      .languge {
        display: flex;
        align-items: center;
        gap: 5px;
        
        span {
          color: $gray-dark;
        }
      }
      
      .notification {
        display: flex;
        align-items: center;
        margin-right: 17px;

        &::after {
          content: attr(data-nbnotif);
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: -5px;
          left: -8px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: $primary-light-color;
          color: $white;
        }
      }
      
      .profile {
        display: flex;
        align-items: center;
        gap: 20px;
        
        .profile-name {
          font-weight: 600;
        }

        .profile-image {
          border-radius: 50%;
          border: 3px solid $black;
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  header  {
    &.header {
      padding: 0 15px;

      .burger-menu {
        display: block;
      }

      .logo {
        margin-left: 0px;
        padding: 25px 0px;
        border-right: none;
        width: 96px;
      }

      .menu {
        gap: 3px;

        .languge {
          span {
            display: none;
          }
        }

        .notification {
          margin-right: 0px;
        }
  
        .profile {
          .profile-name {
            display: none;
          }
  
          .profile-image {
            width: 35px;
            border: 2px solid $black;
          }
        }
      }
    }
  }

  #split-window {
    position: relative;
    display: flex;
    min-height: calc(100vh - 90px);
  }
}

#bat-menu {
  display: flex;
  flex-direction: column;
  flex-basis: 311px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 311px;
  min-height: 100%;
  border-right: 1.2px solid $gray-light;
  padding-bottom: 50px;
  background-color: $white;
  gap: 100px;
  z-index: 22;

  .badge {
    display: inline-block;
    margin-top: 5px;
    text-align: center;
    margin: 20px 30px;
    padding: 12px;
    
    &.green {
      background-color: rgba($color: #8AC923, $alpha: 0.15);
      border: 1.2px solid #8AC923;
      color: #8AC923;
      border-radius: 7px;
    }

    &.purple {
      background-color: rgba($color: #772CF3, $alpha: 0.15);
      border: 1.2px solid #772CF3;
      color: #772CF3;
      border-radius: 7px;
    }
  }

  .title {
    display: inline-block;
    margin: 34px 43px;
  }

  .menu {
    display: flex;
    flex-direction: column;
    gap: 20px;
    list-style: none;

    li {
      cursor: pointer;
      display: flex;
      gap: 11px;
      align-items: center;
      padding-left: 43px;
      padding-top: 2px;
      padding-bottom: 2px;
      border-left: 5px solid $white;
      color: $primary-dark-color;

      &.shift {
        padding-left: 78px;
        margin-top: -25px;
        opacity: 0;
        font-size: 0;
        transition: opacity 0.3s ease-in-out, margin-top 0.2s ease-in-out;

        &.open {
          opacity: 1;
          font-size: 1rem;
          margin-top: -15px;
        }
      }

      &.selected {
        border-left: 5px solid $primary-color;
        color: $primary-color;
      }
    }
  }
}

@media screen and (max-width: 920px) {
  #bat-menu {
    visibility: hidden;
    transform: translateX(-100%);
    width: 0;
    flex-basis: 0;
    
    &.show {
      visibility: visible;
      transform: translateX(0);
      z-index: 2;
      animation: batMenuOpen 0.5s;
      flex-basis: 100%;
    }
    
    &:not(.show) {
      animation: batMenuClose 0.5s;
      opacity: 0;
    }    
  }
}

// elements

.w-100 {
  width: 100%;
}

.badge, .badge-green {
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 2px 2px 5px #00000012;
  }
}

.control-navigate {
  display: flex;
  gap: 40px;
  align-items: center;
  float: right;
  margin-top: 25px;

  svg {
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover {
      transform: scale(1.1) translateY(-3px);
    }
  }
}

.overflow {
  opacity: 0;
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: rgba($gray, $alpha: 0.2);
  backdrop-filter: blur(3px);
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width: 920px) {
  .overflow {
    opacity: 1;
  }
}

.notifblock {
  width: 100%;
  padding: 15px 25px;
  border-radius: 7px;

  .title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 6px;
  }

  .sub-title {
    display: block;
    font-size: 0.9rem;
    margin-bottom: 4px;
  }

  .date {
    z-index: 10;
    display: block;
    font-size: 0.8rem;
    color: #6B6B6B;
  }

  &.success {
    background-color: rgba(#81C313, $alpha: 0.15);
    border: 1.5px solid #81C313;

    .title {
      color: #81C313;
    }
  }

  &.info {
    background-color: rgba(#772CF3, $alpha: 0.15);
    border: 1.5px solid #772CF3;

    .title {
      color: #772CF3;
    }
  }
}

//\ elements
main {
  overflow-x: hidden;
  width: 100%;
  min-height: 100%;
  background-color: $white-dark;
  padding: 50px 30px;

  h2 {
    font-family: 'Poppins', sans-serif;
    color: $primary-dark-color;
    font-weight: 600;
  }

  &.open {
    display: none;
  }
}

#home {
  .grid {
    display: grid;
    grid-template-columns: repeat(22, 1fr);
    gap: 20px;
    margin: 35px 0;

    .box {
      padding: 47px;
      background-color: $white;
      border-radius: 7px;
      border: 1.2px solid $gray-light;
      
      h3 {
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        color: $primary-dark-color;
        margin-bottom: 30px;
      
        &.cursor {
          cursor: pointer;
        }
      }

      &.box-ca {
        grid-column: span 10;
      }

      &.box-consultations {
        grid-column: span 6;
      }

      &.box-td {
        grid-column: span 6;
      }
      
      &.box-eca {
        grid-column: span 16;
      }

      &.box-ar {
        grid-column: span 6;

        .blocks {
          display: flex;
          flex-direction: column;
          gap: 25px;
        }
      }

      .mb-3 {
        margin-bottom: 60px !important;
      }

      .col-2 {
        display: flex;
        justify-content: space-between;
      }


      .sub-title-gray {
        display: block;
        font-size: 1rem;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        color: $gray-dark;
        margin-bottom: 26px;
      }

      .primary-text {
        display: block;
        font-size: 1.25rem;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        color: $primary-color;
        margin-bottom: 10px;
      }

      .secondary-text {
        display: block;
        font-size: 1.25rem;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        color: $primary-dark-color;
        margin-bottom: 20px;
      }

      .sub-text {
        display: block;
        font-size: 0.8rem;
        font-family: 'Poppins', sans-serif;
        font-size: 500;
        color: $gray-dark;
        margin-bottom: 14px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  #home {
    .grid {
      grid-template-columns: repeat(16, 1fr);

      .box {
        &.box-ca {
          grid-column: span 16;
        }

        &.box-consultations {
          grid-column: span 8;
        }
  
        &.box-td {
          grid-column: span 8;
        }

        &.box-ar {
          grid-column: span 16;
        }
      }
    }
  }
}

@media screen and (max-width: 680px) {
  #home {
    .grid {
      gap: 0px;
      row-gap: 20px;
      
      .box {
        padding: 27px;

        &.box-consultations {
          grid-column: span 16;
        }
  
        &.box-td {
          grid-column: span 16;
        }
      }
    }
  }
}

#consultation {
  min-height: inherit;

  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pagination {
      display: flex;
      align-items: center;
      gap: 20px;

      .link {
        color: $primary-color;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(22, 1fr);
    row-gap: 75px;
    margin: 35px 0;
    gap: 20px;
  

    .user-info {
      display: flex;
      gap: 25px;
      grid-column: span 11;
      background-color: rgba($color: #81C313, $alpha: 0.15);
      border: 1.5px solid #81C313;
      border-radius: 7px;
      padding: 25px;
    
      .info-contact {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .userIcon {width: 50px;height: 50px;}
      
        div {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h3 {
            font-family: 'Poppins', sans-serif;
            font-size: 2rem;
            font-weight: 500;
            color: $gray-dark;
          }

          .idClient {color: #81C313;font-weight: bold;}
          .clientBadge {background-color: rgba(#81C313, $alpha: 0.30);border: 1px solid #81C313;padding: 2px 26px; border-radius: 20px;}
          
        }
      }

      .info-call {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex: 1;

        .box {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          background-color: rgba(#81C313, $alpha: 0.30);
          padding: 6px 15px;
          border: 1px solid #81C313;
          border-radius: 5px;
        }
      }
    }

    .dropDown {
      display: flex;
      justify-content: space-between;
      align-items: center;
      grid-column: span 22;
      background-color: #ECECEC;
      padding: 20px 50px;

      .icon {
        cursor: pointer;
        width: 25px;
        height: 25px;
        transition: transform .3s ease-in-out;

        &.show {
          transform: rotateZ(180deg);
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  #consultation {
    .grid {
      .user-info {
        grid-column: span 16;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  #consultation {
    .top-bar {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }

    .grid {
      gap: 0;
      row-gap: 20px;

      .user-info {
        flex-direction: column;
        grid-column: span 22;
        gap: 5px;
        padding: 15px 10px;
        transition: all .3s ease-in-out;

        .info-contact {
          flex: none;
          div {
            h3 {
              font-size: 1.5rem;
            }

            span {
              font-size: 0.7rem;
            }
          }
        }

        .info-call {
          flex: none;

          .box {
            padding: 5px 7px;
          }
        }
      }
    }
  }
}

#cabinetConfiguration {
  min-height: inherit;

  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pagination {
      display: flex;
      align-items: center;
      gap: 20px;

      .link {
        color: $primary-color;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .content-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
    width: 100%;
    padding: 50px 75px;
    background-color: $white;
    margin-top: 100px;
    border-radius: 7px;
    border: 1.2px solid $gray-light;

    h2 {
      color: #81C313;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
    }

    .btn-light {
      cursor: pointer;
      padding: 6px 33px;
      border: none;
      background-color: rgba($primary-color, $alpha: 0.10);
      border-radius: 5px;
    }
  
    .input-light {
      padding: 21px 33px; 
      background-color: #F4F4F4;
      border: none;
      width: 100%;
      max-width: 863px;
    }

    .btn-group {
      display: flex;
      gap: 0;

      button {
        padding: 10px 47px;
        border: none;
        background-color: #F4F4F4;
        color: $black;

        &.primary {
          background-color: rgba($primary-color, $alpha: 0.5);
        }
      }
    }

    .input-file {
      cursor: pointer;
      background-color: #F4F4F4;
      padding: 0;

      input {
        display: none;
        width: 200px;
        margin: 12px 21px;
        -webkit-appearence: none;
        

        &::-webkit-file-upload-button {
          visibility: hidden;
        }

        &::before {
          content: 'Sélection votre logo';
          display: inline-block;
          padding: 5px 8px;
          outline: none;
          white-space: nowrap;
          -webkit-user-select: none;
          -webkit-appearance: none;
          cursor: pointer;
          color: $black;
          font-weight: 400;
          font-size: 1rem;
        }
      }

      .text {
        display: inline-block;
        padding: 17px 25px 17px 8px;
        outline: none;
        white-space: nowrap;
        cursor: pointer;
        color: $black;
        font-weight: 400;
        font-size: 1rem; 
      }

      .button {
        padding: 17px 21px;
        border-left: 3px solid #B0B0B0;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  #cabinetConfiguration {
    .top-bar {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }

    .content-box {
      padding: 40px 30px;

      .input-file {
        input {
          margin: 8px 8px;
        }
        label {
          padding: 12px 14px;
        }
      }
    }
  }
}

#advice {
  min-height: inherit;

  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pagination {
      display: flex;
      align-items: center;
      gap: 20px;

      .link {
        color: $primary-color;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .content-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
    width: 100%;
    padding: 50px 75px;
    background-color: $white;
    margin-top: 100px;
    border-radius: 7px;
    border: 1.2px solid $gray-light;
  
    .filter {
      width: 100%;
      row-gap: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      
      .form-group {
        display: flex;
        gap: 20px;
        align-items: center;

        input, select {
          padding: 7px 11px;
          border: 0.5px solid #969696;
          border-radius: 5px;
          color: #8d8d8d;
        }
      }

      .recherche {
        background-color: rgba(#772CF3, $alpha: 0.5);
        color: $white;
        padding: 8px 17px;
        border: none;
        border-radius: 5px;
      }

      .nouveau {
        cursor: pointer;
        display: flex;
        gap: 24px;
        align-items: center;
        padding: 8px 36px;
        border: none;
        background-color: rgba($primary-color, $alpha: 0.05);
      
        .plus {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          background-color: $white;
        }
      }
    }

    .advice {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      padding: 26px 37px;
      border-radius: 7px;
      margin-top: 27px;
      box-shadow: 10px 10px 40px 0 rgba(#AEAEAE, $alpha: 0.25);

      .top-bar-advice {
        display: flex;
        justify-content: space-between;

        h3 {
          .purple-text {
            color: $primary-color;
          }
        }
      }

      .second-bar-advice {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        div {
          display: flex;
          flex-direction: column;

          .green-text {
            color: #81C313;
          }

          .grey-text {
            color: #787878;
          }
        }

        .commentaire {
          color: #808080;
          font-weight: 400;
        }
      }

      .btn-group-advice {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 8px;

        button {
          padding: 6px 29px;
          border: none;
          border-radius: 5px;
          
          &.modify {
            border: 0.5px solid #969696;
            background-color: $white;
          }

          &.reject {
            background-color: rgba($primary-color, $alpha: 0.5);
          }

          &.publish {
            background-color: rgba($color: #8AC923, $alpha: 0.5);            
          }
        }
      }
    }
  }
}

@media screen and (max-width: 780px) {
  #advice {
    .content-box {
      padding: 40px 30px;

      .advice {
        padding: 26px 15px;
      }
    }
  }
}

#planning {
  min-height: inherit;

  .content-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
    width: 100%;
    padding: 0 0 50px 0;
    background-color: $white;
    margin-top: 100px;
    border-radius: 7px;
    border: 1.2px solid $gray-light; 
    overflow: hidden; 
  
    .select-mode {
      width: 100%;
      display: flex;

      .mode {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;
        border: 1.2px solid #D7D7D7;
        padding: 16px;
        
        &:first-child {
          border-radius: 7px 0 0 7px;
          border-right: none; 
        }
        
        &:last-child {
          border-radius: 0 7px 7px 0;
          border-left: none; 
        }

        &.selected {
          border: 1.2px solid $primary-color;
          border-radius: 7px;
          background-color: rgba($primary-color, $alpha: 0.15);
        }
      }

    }

    .modePlanning {
      margin: 0 65px 35px;
      border: 1.2px solid #D7D7D7;
      border-radius: 7px;
      padding: 30px 50px;
      width: calc(100% - 100px);

      .dateBar {
      margin: 0 65px 35px;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 100px);

      div {
        margin: 0 auto;
        text-align: center;
      }

      h4 {
        text-align: center;
        width: 100%;
      }

      .navigation {
        display: flex;
        gap: 16px;
      }
    }
      
      .choiceBox {
        display: flex;
        flex-direction: column;
        gap: 23px;

        .radioInputs {
          display: flex;
          flex-wrap: wrap;
          gap: 34px;
          align-items: center;

          div {
            display: flex;
            gap: 15px;
            
            input {
              accent-color: #81C313;
            }
          }
        }

        .select {
          width: 100%;

          select {
            width: 100%;
            max-width: 320px;
            padding: 14px 25px 14px 25px;
            border: none;
            background-color: #F4F4F4;
            border-radius: 5px;
            color: #656565;
          }
        }
      }
    }

  
    .btnGreen {
      cursor: pointer;
      background-color: rgba(#81C313, $alpha: 0.15);
      border: 1px solid #81C313;
      border-radius: 5px;
      padding: 5px 17px;
      margin-top: 5px;
      
      &.large {
        padding: 5px 38px;
      }
    }

  .btnGreen-arrow {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#81C313, $alpha: 0.15);
    border: 1px solid #81C313;
    width: 70px;
    height: 50px;
    border-radius: 5px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
  
    .dateBar {
      margin: 0 65px 35px;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 100px);

      div {
        margin: 0 auto;
        text-align: center;
      }

      h4 {
        text-align: center;
        width: 100%;
      }

      .navigation {
        display: flex;
        gap: 16px;
      }
    }

    .content {
      margin: 0 65px 35px;
      width: calc(100% - 100px);
      overflow-x: scroll;

      h5 {
        font-size: 1.5rem;
        color: $gray;
      }
      .badges {
        display: flex;
        gap: 10px;
        overflow-x: scroll;
        
        .badge {
          display: inline-block;
          margin-top: 5px;
          background: rgba(#8AC923, $alpha: 0.34);
          border: 1px solid #8AC923;
          color: $gray;
          padding: 7px 47px;
          border-radius: 5px;
          white-space: nowrap;

          &.blue {
            background: rgba(#2950DD, $alpha: 0.34);
            border: 1px solid #2950DD;
          }

          &.red {
            background: rgba(#C9235F, $alpha: 0.34);
            border: 1px solid #C9235F; 
          }

          &.orange {
            background: rgba(#B89043, $alpha: 0.34);
            border: 1px solid #B89043; 
          }

          &.yellow {
            background: rgba(#EFCC86, $alpha: 0.34);
            border: 1px solid #EFCC86; 
          }
        }
      }

      .emplois {
        display: table;
        flex-direction: column;
        width: 100%;
        gap: 50px;
        row-gap: 15px;
        justify-content: space-around;
        margin-top: 5px;
        margin-bottom: 15px;

        .emploi {
          width: 100%;
          
          .part {
            display: flex;
            justify-content: space-between;
            width: 100%;
            flex-wrap: nowrap;
            gap: 8px;
            border: 10px;
            padding: 18px 19px;
            box-shadow: 10px 10px 40px 0 rgba(#A6A6A6, $alpha: 0.25);
            min-height: 220px;

            .expert-badge {
              color: #000;
              background: rgba(#8AC923, $alpha: 0.34);
              border: 1px solid #8AC923;
              width: 100%;
              text-align: center;
              border-radius: 300px;

              &.blue {
                background: rgba(#2950DD, $alpha: 0.34);
                border: 1px solid #2950DD;
              }

              &.red {
                background: rgba(#C9235F, $alpha: 0.34);
                border: 1px solid #C9235F; 
              }

              &.orange {
                background: rgba(#B89043, $alpha: 0.34);
                border: 1px solid #B89043; 
              }

              &.yellow {
                background: rgba(#EFCC86, $alpha: 0.34);
                border: 1px solid #EFCC86; 
              }
            }

            .top {
              padding: 5px 13px;
              box-shadow: 10px 10px 40px 0 rgba(#A6A6A6, $alpha: 0.25);
              border-radius: 10px;
              justify-content: center;
              align-items: center;
            }
  
            .selected {
              background-color: rgba(#8AC923, $alpha: 0.34);
              color: #000;
              border: 1px solid #8AC923;
            }
  
            div {
              display: flex;
              flex-direction: column;
              gap: 18px;    
              color: #C10000;
              width: 120px;
  
              div {
                box-shadow: 10px 10px 40px 0 rgba(#A6A6A6, $alpha: 0.25);
                border-radius: 10px;
  
                &:not(.top) {
                  padding: 11px 10px;
                  gap: 15px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  height: 100%;
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
  }

  .modeLecture {
    padding: 10px 66px 50px;

    h3 {
      color: #C10000;
      font-family: "Poppins", sans-serif;
    }

    .table {
      display: inline-flex;
      justify-content: space-between;
      box-shadow: 10px 10px 40px 0 rgba(#A6A6A6, $alpha: 0.25);
      border-radius: 10px;
      width: 100%;
      padding: 60px 40px;

      .ligne {
        flex: 1 1 0;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        padding: 23px 20px;
        gap: 35px;

        &.selected {
          background-color: rgba(#3EA336, $alpha: 0.20);
        }

        div {
          padding: 36px;
          background-color: $white;
          box-shadow: 10px 10px 40px 0 rgba(#A6A6A6, $alpha: 0.25);
          text-align: center;
          border-radius: 10px;

          &.names {
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 175px;
          }

          .text-purple {
            color: $primary-color;
          }

          .text-green {
            color: #3EA336;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  #planning {
    .modeLecture {
      padding: 10px 33px 40px;

      .table {
        overflow-x: scroll;
        padding: 30px 20px;

        .ligne {
          padding: 12px 10px;

          div {
            padding: 12px;
          }
        }
      }
    }
  }
}

#analyseClient {
  min-height: inherit;
  
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pagination {
      display: flex;
      align-items: center;
      gap: 20px;

      .link {
        color: $primary-color;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .filter-bar {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
    width: 100%;
    padding: 50px 75px;
    background-color: $white;
    margin-top: 50px;
    border-radius: 7px;
    border: 1.2px solid $gray-light;

    .date {
      z-index: 10;
      display: flex;
      gap: 35px;
      align-items: center;

      input {
        background-color: rgba($primary-color, $alpha: 0.1);
        border: none;
        border-radius: 5px;
        min-width: 120px;
        max-width: 292px;
      }
    }

    .recherche {
      display: flex;
      gap: 35px;
      align-items: center;

      input {
        padding: 0.75rem 0.75rem;
        border: 0.5px solid $black;
        border-radius: 5px;
      }

      select {
        padding: 0.75rem 0.75rem;
        border: none;
        border-radius: 5px;
        box-shadow: 10px 10px 40px rgba(#A0A0A0, $alpha: 0.25);
      }
    }
  }

  .tableContainer {
    margin-top: 50px;
    width: 100%;
    display: inherit;
    overflow: auto;
  }

  table {
    margin-top: 50px;
    background-color: $white;
    border-collapse: collapse;
    display: table;

    thead {
      border: 1.2px solid #D7D7D7;
      border-radius: 7px;
      tr > th {
        padding: 22px;
      }
    }

    tbody {
      tr > td {
        padding: 22px;
        border: 1.2px solid #D7D7D7;
        text-align: center;

        &.align-left {
          text-align: right;
        }
      }

      .badge {
        display: inline-block;
        margin-top: 5px;
        padding: 3px 15px;
        border-radius: 300px;
        background-color: rgba($color: #F20000, $alpha: 0.15);
        color: #B60000;
        font-family: "Poppins", sans-serif;
      }

      .type {
        padding: 5px 18px; 
        border-radius: 5px;
        background-color: rgba($primary-color, $alpha: 0.1);
        font-family: "Poppins", sans-serif;
      }

      .color-green {
        color: #81C313;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  #analyseClient {
    .filter-bar {
      flex-wrap: wrap;
      padding: 15px 15px;
    }
  }
}

#promoCode {
  min-height: inherit;
  
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pagination {
      display: flex;
      align-items: center;
      gap: 20px;

      .link {
        color: $primary-color;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .filter-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    width: 100%;
    padding: 50px 75px;
    background-color: $white;
    margin-top: 50px;
    border-radius: 7px;
    border: 1.2px solid $gray-light; 
    overflow: hidden;
    
    .nouveau {
      cursor: pointer;
      display: flex;
      gap: 24px;
      align-items: center;
      padding: 8px 36px;
      border: none;
      background-color: rgba($primary-color, $alpha: 0.05);
    
      .plus {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: $white;
      }
    }

    .recherche {
      display: flex;
      gap: 35px;
      align-items: center;

      input {
        padding: 0.75rem 0.75rem;
        border: 0.5px solid $black;
        border-radius: 5px;
      }

      select {
        padding: 0.75rem 0.75rem;
        border: none;
        border-radius: 5px;
        box-shadow: 10px 10px 40px rgba(#A0A0A0, $alpha: 0.25);
      }
    }
  }

  .tableContainer {
    margin-top: 50px;
    width: 100%;
    display: inherit;
    overflow: auto;
  }

  table {
    margin-top: 50px;
    background-color: $white;
    border-collapse: collapse;
    display: inherit;

    thead {
      border: 1.2px solid #D7D7D7;
      border-radius: 7px;
      tr > th {
        padding: 15px;
      }
    }

    tbody {
      tr > td {
        padding: 25px;
        border: 1.2px solid #D7D7D7;
        text-align: center;

        &.align-left {
          text-align: right;
        }
      }

      .badge {
        display: inline-block;
        margin-top: 5px;
        padding: 3px 15px;
        border-radius: 300px;
        background-color: rgba($color: #F20000, $alpha: 0.15);
        color: #B60000;
        font-family: "Poppins", sans-serif;
      }

      .type {
        padding: 5px 18px; 
        border-radius: 5px;
        background-color: rgba($primary-color, $alpha: 0.1);
        font-family: "Poppins", sans-serif;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  #promoCode {
    .filter-bar {
      padding: 30px 25px;
    }
  }
}

#purchasePackage {
  min-height: inherit;
  
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pagination {
      display: flex;
      align-items: center;
      gap: 20px;

      .link {
        color: $primary-color;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .filter-bar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    width: 100%;
    padding: 50px 75px;
    background-color: $white;
    margin-top: 50px;
    border-radius: 7px;
    border: 1.2px solid $gray-light; 

    .date {
      z-index: 10;
      display: flex;
      gap: 35px;
      align-items: center;

      input {
        background-color: rgba($primary-color, $alpha: 0.1);
        border: none;
        border-radius: 5px;
        min-width: 120px;
        max-width: 292px;
      }
    }

    .recherche {
      padding: 0.75rem 15px;
      border-radius: 5px;
      border: none;
      background-color: rgba($primary-color, $alpha: 0.5);
      color: $white;
    }
  }

  .content-box {
    width: 100%;
    padding: 50px 75px;
    background-color: $white;
    margin-top: 50px;
    border-radius: 7px;
    border: 1.2px solid $gray-light; 
    overflow: hidden;

    h3 {
      color: $gray;
      font-size: 1.2rem;
      font-family: "poppins", sans-serif;
    }

    .container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 25px;
      
      .box {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 38px 25px;
        min-width: 220px;
        max-width: 240px;
        border: 1.2px solid $gray-light;
        border-radius: 7px;
        gap: 100px;
        margin: 0 auto;

        .box-top {
          display: flex;
          justify-content: space-between;

          .badge {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px 10px;
            background-color: rgba($color: $primary-color, $alpha: 0.5);
            border-radius: 5px;
            height: 29px;
          }

          span {
            color: #707070;
          }
        }

        .box-body {
          h4 {
            font-size: 2.5rem;
          }

          .pourcent {
            display: flex;
            align-items: center;
            gap: 7px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  #purchasePackage {
    .filter-bar {
      flex-wrap: wrap;
      padding: 15px 15px;
    }
  }
}

#adminAccount {
  min-height: inherit;
  
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pagination {
      display: flex;
      align-items: center;
      gap: 20px;

      .link {
        color: $primary-color;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .filter-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    width: 100%;
    padding: 50px 75px;
    background-color: $white;
    margin-top: 50px;
    border-radius: 7px;
    border: 1.2px solid $gray-light;
    
    .nouveau {
      cursor: pointer;
      display: flex;
      gap: 24px;
      align-items: center;
      padding: 8px 36px;
      border: none;
      background-color: rgba($primary-color, $alpha: 0.05);
    
      .plus {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: $white;
      }
    }

    .recherche {
      display: flex;
      gap: 35px;
      align-items: center;

      input {
        padding: 0.75rem 0.75rem;
        border: 0.5px solid $black;
        border-radius: 5px;
      }

      select {
        padding: 0.75rem 0.75rem;
        border: none;
        border-radius: 5px;
        box-shadow: 10px 10px 40px rgba(#A0A0A0, $alpha: 0.25);
      }
    }
  }

  .tableContainer {
    margin-top: 50px;
    width: 100%;
    display: inherit;
    overflow: auto;
  }

  table {
    margin-top: 50px;
    background-color: $white;
    border-collapse: collapse;
    overflow: auto;
    display: table;

    thead {
      border: 1.2px solid #D7D7D7;
      border-radius: 7px;
      tr > th {
        padding: 22px;
      }
    }

    tbody {
      tr > td {
        padding: 22px;
        border: 1.2px solid #D7D7D7;

        &.align-left {
          text-align: right;
        }
      }

      .badge {
        display: inline-block;
        margin-top: 5px;
        padding: 3px 15px;
        border-radius: 300px;
        background-color: rgba($color: #F20000, $alpha: 0.15);
        color: #B60000;
        font-family: "Poppins", sans-serif;
      }

      .type {
        padding: 5px 18px; 
        border-radius: 5px;
        background-color: rgba($primary-color, $alpha: 0.1);
        font-family: "Poppins", sans-serif;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  #adminAccount {
    .filter-bar {
      padding: 25px 15px;
    }
  }
}

#clientList {
  min-height: inherit;
  
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pagination {
      display: flex;
      align-items: center;
      gap: 20px;

      .link {
        color: $primary-color;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .form {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin-top: 25px;
    
    .dropdown-header {
      padding: 0.75rem 0.75rem;
      border: 1px solid #ced4da;
      border-radius: 6px;
      min-width: 100px;
      height: 100%;
      background-color: $white;
    }

    input, select, button {
      padding: 0.75rem 0.75rem;
      border: 1px solid #ced4da;
      border-radius: 6px;
      min-width: 100px;
    }

    button {
      cursor: pointer;
      background-color: rgba($primary-color, $alpha: 0.5);
      color: $white;
    }
  }

  .nbResult {
    color: $gray;
    margin: 20px 0;
  }
  
  .tableContainer {
    margin-top: 50px;
    width: 100%;
    display: inherit;
    overflow: auto;
  }

  table {
    margin-top: 50px;
    background-color: $white;
    border-collapse: collapse;
    display: table;

    thead {
      border: 1.2px solid #D7D7D7;
      border-radius: 7px;
      tr > th {
        padding: 22px;
      }
    }

    tbody {
      tr > td {
        padding: 22px;
        border: 1.2px solid #D7D7D7;
        text-align: center;

        &.align-left {
          text-align: right;
        }
      }

      .badge {
        display: inline-block;
        margin-top: 5px;
        padding: 3px 15px;
        border-radius: 300px;
        background-color: rgba($color: #F20000, $alpha: 0.15);
        color: #B60000;
        font-family: "Poppins", sans-serif;
      }
      .badge-green {
        padding: 3px 15px;
        border-radius: 300px;
        background-color: rgba($color: #81C313, $alpha: 0.15);
        color: #000000;
        font-family: "Poppins", sans-serif;
        margin-left: 10px;
      }

      .color {
        width: 36px;
        height: 11px;
        border-radius: 300px;
        background-color: $black;
      }

      select {
        border: none;
      }

      .type {
        padding: 5px 18px; 
        border-radius: 5px;
        background-color: rgba($primary-color, $alpha: 0.1);
        font-family: "Poppins", sans-serif;
      }

      .color-green {
        color: #81C313;
      }
    }
  }
}

#expert {
  min-height: inherit;
  
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pagination {
      display: flex;
      align-items: center;
      gap: 20px;

      .link {
        color: $primary-color;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .recherche {
    display: flex;
    margin: 20px 0 30px;
    float: right;
    gap: 35px;
    align-items: center;

    input {
      padding: 0.75rem 0.75rem;
      border: 0.5px solid $black;
      border-radius: 5px;
    }

    select {
      padding: 0.75rem 0.75rem;
      border: none;
      border-radius: 5px;
      box-shadow: 10px 10px 40px rgba(#A0A0A0, $alpha: 0.25);
    }
  }

  .tableContainer {
      margin-top: 50px;
      width: 100%;
      display: inherit;
      overflow: auto;

      table {
        display: table;
        width: 100%;
        background-color: $white;
        border-collapse: collapse;
    
        thead {
          border: 1.2px solid #D7D7D7;
          border-radius: 7px;
          tr > th {
            padding: 22px;
          }
        }
    
        tbody {
          tr > td {
            padding: 22px;
            border: 1.2px solid #D7D7D7;
            text-align: center;
    
            &.align-left {
              text-align: right;
            }
          }
    
          .badge {
            display: inline-block;
            margin-top: 5px;
            padding: 3px 15px;
            border-radius: 300px;
            background-color: rgba($color: #F20000, $alpha: 0.15);
            color: #B60000;
            font-family: "Poppins", sans-serif;
          }
          .badge-green {
            padding: 3px 15px;
            border-radius: 300px;
            background-color: rgba($color: #81C313, $alpha: 0.15);
            color: #000000;
            font-family: "Poppins", sans-serif;
          }
    
          .spaceHeight {
            line-height: 30px;
          }
    
          .icon {
            display: flex;
            align-items: center;
            gap: 15px;
          }
    
          .color {
            width: 36px;
            height: 11px;
            border-radius: 300px;
            background-color: $black;
          }
    
          .type {
            padding: 5px 18px; 
            border-radius: 5px;
            background-color: rgba($primary-color, $alpha: 0.1);
            font-family: "Poppins", sans-serif;
          }
    
          .color-green {
            color: #81C313;
          }
        }
      }
  }  
}

#analyseExpert {
  min-height: inherit;
  overflow-x: overlay;
  
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pagination {
      display: flex;
      align-items: center;
      gap: 20px;

      .link {
        color: $primary-color;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .content-box {
    width: 100%;
    padding: 50px 75px;
    background-color: $white;
    margin-top: 50px;
    border-radius: 7px;
    border: 1.2px solid $gray-light; 
    overflow-x: scroll;
    
    .filter {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      gap: 25px;
      margin-bottom: 25px;
  
      .date {
        z-index: 10;
        display: flex;
        gap: 35px;
        align-items: center;

        input {
          background-color: rgba($primary-color, $alpha: 0.1);
          border: none;
          border-radius: 5px;
          min-width: 120px;
          max-width: 292px;
        }
      }
    }

    .recherche {
      padding: 0.75rem 15px;
      border-radius: 5px;
      border: none;
      background-color: rgba($primary-color, $alpha: 0.5);
      color: $white;
    }
  }
  .tableContainer {
    margin-top: 50px;
    width: 100%;
    display: inherit;
    overflow: auto;

    table {
      display: table;
      margin-top: 50px;
      background-color: $white;
      border-collapse: collapse;
      width: 100%;

      thead {
        border-radius: 7px;
        tr > th, td {
          padding: 22px;
          &.head {
            border: 1.2px solid #D7D7D7;
            text-align: center;
            font-size: 1.5rem;
            border-left: 11px solid rgba(#772CF3, $alpha: 0.5);
            background-color: rgba($primary-color, $alpha: 0.04);
          }

        }
      }
      
      .second-head {
        background-color: rgba($primary-color, $alpha: 0.04); 
      }

      .sb {
        display: flex;
        justify-content: space-between;
      }

      .br {
        border-right: 11px solid rgba(#772CF3, $alpha: 0.5);
      }
      
      tbody {
        tr > td, th {
          padding: 22px;
          border: 1.2px solid #D7D7D7;
          text-align: center;

          &.align-left {
            text-align: right;
          }
        }

        .badge {
          display: inline-block;
          margin-top: 5px;
          padding: 3px 15px;
          border-radius: 300px;
          background-color: rgba($color: #F20000, $alpha: 0.15);
          color: #B60000;
          font-family: "Poppins", sans-serif;
        }
        .badge-green {
          display: inline-block;
          margin-top: 5px;
          padding: 3px 15px;
          border-radius: 300px;
          background-color: rgba($color: #81C313, $alpha: 0.15);
          color: #000000;
          font-family: "Poppins", sans-serif;
          margin-left: 10px;
        }

        .color {
          width: 36px;
          height: 11px;
          border-radius: 300px;
          background-color: $black;
        }

        .type {
          padding: 5px 18px; 
          border-radius: 5px;
          background-color: rgba($primary-color, $alpha: 0.1);
          font-family: "Poppins", sans-serif;
        }

        .color-green {
          color: #81C313;
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  #analyseExpert {
    .content-box {
      flex-wrap: wrap;
      padding: 25px 20px;
    }
  }
}

#configurationPackage {
  min-height: inherit;
  
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pagination {
      display: flex;
      align-items: center;
      gap: 20px;

      .link {
        color: $primary-color;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .filter-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    width: 100%;
    padding: 50px 75px;
    background-color: $white;
    margin-top: 50px;
    border-radius: 7px;
    border: 1.2px solid $gray-light; 
    overflow: hidden;
    
    .nouveau {
      cursor: pointer;
      display: flex;
      gap: 24px;
      align-items: center;
      padding: 8px 36px;
      border: none;
      background-color: rgba($primary-color, $alpha: 0.05);
    
      .plus {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: $white;
      }
    }

    .recherche {
      display: flex;
      gap: 35px;
      align-items: center;

      input {
        padding: 0.75rem 0.75rem;
        border: 0.5px solid $black;
        border-radius: 5px;
      }

      select {
        padding: 0.75rem 0.75rem;
        border: none;
        border-radius: 5px;
        box-shadow: 10px 10px 40px rgba(#A0A0A0, $alpha: 0.25);
      }
    }
  }

  .tableContainer {
    margin-top: 50px;
    width: 100%;
    display: inherit;
    overflow: auto;
  }

  table {
    display: inherit;
    width: 100%;
    margin-top: 50px;
    background-color: $white;
    border-collapse: collapse;

    thead {
      border: 1.2px solid #D7D7D7;
      border-radius: 7px;
      tr > th {
        padding: 22px;
      }
    }

    tbody {
      tr > td {
        padding: 22px;
        border: 1.2px solid #D7D7D7;
        text-align: center;

        &.align-left {
          text-align: right;
        }
      }

      .badge {
        display: inline-block;
        margin-top: 5px;
        padding: 3px 15px;
        border-radius: 300px;
        background-color: rgba($color: #F20000, $alpha: 0.15);
        color: #B60000;
        font-family: "Poppins", sans-serif;
      }
      .badge-green {
        display: inline-block;
        margin-top: 5px;
        padding: 3px 15px;
        border-radius: 300px;
        background-color: rgba($color: #81C313, $alpha: 0.15);
        color: #000000;
        font-family: "Poppins", sans-serif;
        margin-left: 10px;
      }

      .color {
        width: 36px;
        height: 11px;
        border-radius: 300px;
        background-color: $black;
      }

      .type {
        padding: 5px 18px; 
        border-radius: 5px;
        background-color: rgba($primary-color, $alpha: 0.1);
        font-family: "Poppins", sans-serif;
      }

      .color-green {
        color: #81C313;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  #configurationPackage {
    .filter-bar {
      padding: 30px 25px;
    }
  }
}

// animation 
@keyframes batMenuOpen {
  0% {
    visibility: hidden;
    transform: translateX(-100%);
    flex-basis: 0;
    width: 0;
  }

  1% {
    visibility: visible;
  }

  99% {
    visibility: visible;
  }

  100% {
    visibility: visible;
    transform: translateX(0px);
    flex-basis: 100%;
    width: 100%;
  }
}

@keyframes batMenuClose {
  0% {
    visibility: visible;
    transform: translateX(0px);
    flex-basis: 100%;
    width: 100%;
  }

  1% {
    visibility: visible;
  }

  99% {
    visibility: visible;
  }

  100% {
    visibility: hidden;
    transform: translateX(-100%);
    flex-basis: 0px;
    width: 0px;
  }
}

// expert

#homeExpert {
  h3 {
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: rgba($color: $primary-dark-color, $alpha: .7);
  }
  
  .expert-info-box {
    display: flex;
    gap: 25px;
    align-items: center;
    padding: 80px 65px;
    border: 1.2px solid $gray-light;
    border-radius: 7px;
    background-color: $white;

    .user-logo {
      width: 150px;
      height: 150px;
      stroke-width: 1px;
    }

    .user-info {
      .name {
        display: block;
        font-size: 1.5rem;
        color: $primary-dark-color;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
      }

      .category, .nb-consultation {
        display: block;
        font-size: 1rem;
        color: rgba($primary-dark-color, $alpha: .70);
        font-family: 'Poppins', sans-serif;
        font-weight: 400;  
      }

      .review {
        display: flex;
        align-items: center;
        gap: 13px;
        font-size: 1rem;
        color: rgba($primary-dark-color, $alpha: .70);
        font-family: 'Poppins', sans-serif;
        font-weight: 400;  
      }
    }
  }

  .tableContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(381px, 1fr));
    gap: 65px;
    margin-top: 40px;

    table {
      text-align: center;
      display: table;
      margin-top: 50px;
      background-color: $white;
      border-collapse: collapse;
      color: #424242;
      font-family: 'Poppins', sans-serif;

      thead {
        border: 1.2px solid #8AC923;
        border-radius: 7px;
        background-color: rgba(#8AC923, .15);
        
        tr > td {
          padding: 22px;
          font-size: 1.2rem;
          color: #000000;
          font-weight: 500;
        }
      }

      tbody {
        tr > th {
          padding: 22px;
          border: 1.2px solid #D7D7D7;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 720px){
  #homeExpert {
    .expert-info-box {
      padding: 20px 15px;
    
      .user-logo {
        width: 80px;
        height: 80px;
      }
    }

    .tableContainer {
      grid-template-columns: 100%;
      gap: 40px;
      margin-top: 20px;
      max-width: 100%;
    
      td, th {
        padding: 5px;
      }
    }
  }
}

#myMessages {
  h3 {
    font-size: 1.4rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: rgba($color: $primary-dark-color, $alpha: 1);
  }

  .message-box {
    padding: 20px 40px;
    background-color: $white;
    box-shadow: 10px 10px 40px 0 rgba(#AEAEAE, $alpha: 0.25);
    border-radius: 7px;
    margin-top: 25px;

    .user-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      .user-name {
        color: #8AC923;
        font-weight: 500;
        font-size: 2rem;
        font-family: 'Poppins', sans-serif;
      }

      .date-message {
        font-family: 'Poppins', sans-serif;
      }
    }

    .desc {
      display: flex;
      gap: 18px;
      align-items: center;
      margin: 24px 0;

      svg {
        flex-basis: 70px;
        min-width: 70px;
        min-height: 70px;
        stroke: #C3C3C3;
      }
    }

    .btn-left {
      display: flex;
      justify-content: flex-end;
      
      button {
        cursor: pointer;
        border-radius: 5px;
        border: none;
        background-color: rgba($color: $primary-color, $alpha: 0.5);
        padding: 5px 14px;
        color: $white;
      }
    }
  }
}

#consultationExpert {
  h3 {
    font-size: 1.4rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: rgba($color: $primary-dark-color, $alpha: 1);
  }

  h4.text-center {
    text-align: center;
    margin-top: 85px;
    color: $primary-dark-color;
    font-weight: 500;
  }

  .consultation-box {
    padding: 33px 40px;
    background-color: $white;
    border-radius: 7px;
    box-shadow: 10px 10px 40px 0 rgba(#AEAEAE, $alpha: 0.25);
    margin: 32px 0;
  
    h4 {
      color: $primary-dark-color;
      font-size: 1.2rem;
      margin-bottom: 10px;
    }

    .tableContainer {
      display: inherit;
      overflow-x: auto;
      width: 100%;
      
      table {
        margin-top: 50px;
        background-color: $white;
        border-collapse: collapse;
        overflow: auto;
        display: table;
    
        thead {
          border: 1.2px solid #D7D7D7;
          border-radius: 7px;
          tr > th {
            padding: 22px;
          }
        }
    
        tbody {
          tr > td {
            padding: 22px;
            border: 1.2px solid #D7D7D7;
    
            &.align-left {
              text-align: right;
            }
          }
    
          .badge {
            display: inline-block;
            margin-top: 5px;
            padding: 3px 15px;
            border-radius: 300px;
            background-color: rgba($color: #F20000, $alpha: 0.15);
            color: #B60000;
            font-family: "Poppins", sans-serif;
          }
    
          .type {
            padding: 5px 18px; 
            border-radius: 5px;
            background-color: rgba($primary-color, $alpha: 0.1);
            font-family: "Poppins", sans-serif;
          }
        }
      } 
    }
  }
}

#mails {
  .mail-box {
    padding: 20px 40px;
    background-color: $white;
    box-shadow: 10px 10px 40px 0 rgba(#AEAEAE, $alpha: 0.25);
    border-radius: 7px;
    margin-top: 25px;
  
    .top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      .user {
        padding: 4px 16px;
        display: flex;
        align-items: center;
        gap: 15px;
        background-color: rgba(#F20000, $alpha: 0.15);
        border: 0.5px solid #F20000;
        border-radius: 300px;
        max-width: 300px;
        width: 100%;
        white-space: nowrap;
      }

      .date {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        white-space: nowrap;
      }
    }

    .title {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      margin: 10px 0 15px;
    }

    p {
      font-family: 'Poppins', sans-serif;
      margin-bottom: 20px;
    }

    .btn {
      display: flex;
      justify-content: flex-end;

      button {
        color: $white;
        background-color: rgba($color: $primary-color, $alpha: 0.5);
        border: none;
        padding: 7px 14px;
        border-radius: 5px;
      }
    }
  }
}

#adviceExpert {
  .advice-box {
    padding: 20px 40px;
    background-color: $white;
    box-shadow: 10px 10px 40px 0 rgba(#AEAEAE, $alpha: 0.25);
    border-radius: 7px;
    margin-top: 25px;

    .top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      gap: 5px;

      .title {
        font-size: 1.3rem;

        .purple-text {
          color: $primary-color;
        }
      }

      .stars {
        white-space: nowrap;
      }
    }

    p {
      font-family: 'Poppins', sans-serif;
    }

    hr {
      margin: 24px 0;
      border-top: 1px solid $gray-light;
    }

    .alert-box {
      background-color: rgba($primary-color, $alpha: 0.04);
      padding: 16px 21px;
      border-left: 11px solid rgba($primary-color, $alpha: 0.5);
    }

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 15px;

      textarea {
        resize: none;
        display: block;
        padding: 5px 14px;
        border-radius: 12px;
        border: 1px solid #AFAFAF;
      }

      .btn {
        align-self: flex-end;
      }

      button {
        cursor: pointer;
        border-radius: 5px;
        border: none;
        background-color: rgba($primary-color,$alpha: 0.5);
        padding: 5px 14px;
        color: #FFFFFF;
      }
    }
  }
}

#profileExpert {
  padding: 0;

  .grey-part {
    background-color: #EFEFEF;
    padding: 18px 44px;
  }

  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      padding: 7px 17px;
      border: none;
      border-radius: 5px;
      background-color: rgba($primary-color, $alpha: 0.5);
      color: $white;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: translateY(-5px);
      }
    }
  }

  #form {
    visibility: hidden;
  }
 
  #modifyBack {
    visibility: hidden;
  }
  
  .drag-drop {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    padding: 30px;
    margin: 20px 0;
    height: 215px;
    width: 215px;
    background-color: $white;
    border: 1px solid #AFAFAF;

    .image {
      max-width: 100px;
      max-height: 100px;
    }

    .iconify {
      height: 70px;
      width: 70px;
      color: #E8E8E8;
    }
  }

  .container {
    padding: 18px 44px;

    .name {
      display: block;
      font-family: 'Poppins', sans-serif;
      font-size: 2rem;
    }

    .headline {
      display: block;
      font-family: 'Poppins', sans-serif;
      font-size: 1.1rem;
      margin-bottom: 80px;
    }

    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;

      .form-group {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 480px;
        width: 100%;

        label {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 1.2rem;
        }

        input {
          padding: 17px 30px;
          border-radius: 12px;
          border: 1px solid #AFAFAF;
        }
      
        .input-tags {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding: 17px 30px;
          border-radius: 12px;
          border: 1px solid #AFAFAF;
          background-color: $white;
          gap: 11px;

          select {
            padding: 0;
            border: none;
            border-radius: 0;
            width: 25px;

            &:focus {
              outline: none;
            }
          }

          .tag {
            padding: 2px 16px;
            width: max-content;
            border-radius: 300px;
            
            &:nth-child(2n+1) {  
              background-color: rgba(#F20000, $alpha: 0.15);
              color: #F20000;
            }

            &:nth-child(2n) {  
              background-color: rgba(#81C313, $alpha: 0.15);
              color: #81C313;
            }
          }
        }
      }
    }
  }
}

#myPlanning {
  .dateBar {
    margin: 0 65px 35px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 100px);

    div {
      margin: 0 auto;
      text-align: center;
    }

    h4 {
      text-align: center;
      width: 100%;
    }

    .navigation {
      display: flex;
      gap: 16px;
    }
  }

  .content {
    margin: 0 65px 35px;
    width: calc(100% - 100px);

    h5 {
      font-size: 1.5rem;
      color: $gray;
    }
    .badges {
      display: flex;
      gap: 10px;
      overflow-x: scroll;
      
      .badge {
        display: inline-block;
        margin-top: 5px;
        background: rgba(#8AC923, $alpha: 0.34);
        border: 1px solid #8AC923;
        color: $gray;
        padding: 7px 47px;
        border-radius: 5px;
        white-space: nowrap;

        &.blue {
          background: rgba(#2950DD, $alpha: 0.34);
          border: 1px solid #2950DD;
        }

        &.red {
          background: rgba(#C9235F, $alpha: 0.34);
          border: 1px solid #C9235F; 
        }

        &.orange {
          background: rgba(#B89043, $alpha: 0.34);
          border: 1px solid #B89043; 
        }

        &.yellow {
          background: rgba(#EFCC86, $alpha: 0.34);
          border: 1px solid #EFCC86; 
        }
      }
    }

    .emplois {
      display: table;
      flex-direction: column;
      width: 100%;
      gap: 50px;
      row-gap: 15px;
      justify-content: space-around;
      margin-top: 5px;
      margin-bottom: 15px;

      .emploi {
        width: 100%;
        
        .part {
          display: flex;
          justify-content: space-between;
          width: 100%;
          flex-wrap: nowrap;
          gap: 8px;
          border: 10px;
          padding: 18px 19px;
          box-shadow: 10px 10px 40px 0 rgba(#A6A6A6, $alpha: 0.25);
          min-height: 220px;

          .expert-badge {
            color: #000;
            background: rgba(#8AC923, $alpha: 0.34);
            border: 1px solid #8AC923;
            width: 100%;
            text-align: center;
            border-radius: 300px;

            &.blue {
              background: rgba(#2950DD, $alpha: 0.34);
              border: 1px solid #2950DD;
            }

            &.red {
              background: rgba(#C9235F, $alpha: 0.34);
              border: 1px solid #C9235F; 
            }

            &.orange {
              background: rgba(#B89043, $alpha: 0.34);
              border: 1px solid #B89043; 
            }

            &.yellow {
              background: rgba(#EFCC86, $alpha: 0.34);
              border: 1px solid #EFCC86; 
            }
          }

          .top {
            padding: 5px 13px;
            box-shadow: 10px 10px 40px 0 rgba(#A6A6A6, $alpha: 0.25);
            border-radius: 10px;
            justify-content: center;
            align-items: center;
          }

          .selected {
            background-color: rgba(#8AC923, $alpha: 0.34);
            color: #000;
            border: 1px solid #8AC923;
          }

          div {
            display: flex;
            flex-direction: column;
            gap: 18px;    
            color: #C10000;
            width: 120px;

            div {
              box-shadow: 10px 10px 40px 0 rgba(#A6A6A6, $alpha: 0.25);
              border-radius: 10px;

              &:not(.top) {
                padding: 11px 10px;
                gap: 15px;
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;
                width: auto;
              }
            }
          }
        }
      }
    }
  }

  .btnGreen {
    cursor: pointer;
    background-color: rgba(#81C313, $alpha: 0.15);
    border: 1px solid #81C313;
    border-radius: 5px;
    padding: 5px 17px;
    margin-top: 5px;
    
    &.large {
      padding: 5px 38px;
    }
  }

  .btnGreen-arrow {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#81C313, $alpha: 0.15);
    border: 1px solid #81C313;
    width: 70px;
    height: 50px;
    border-radius: 5px;

    svg {
      width: 24px;
      height: 24px;
  }
  }

  .top-bar {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 11px;

    .top-part {
      display: flex;
      justify-content: flex-end;

    }

    .bottom-part {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      .navigation {
        display: flex;
        gap: 16px;
      }

      .date {
        color: $gray;
        font-size: 1.5rem;
      }
    }

    button.btnGreen {
      background-color: rgba($color: #81C313, $alpha: 0.15);
      border: 1px solid #81C313;
      color: $gray;
      padding: 8px 20px;
      border-radius: 5px;

      svg {
        width: 24px;
        height: 24px;
      }

      &.larg {
        padding: 8px 40px;
      }
    }
  }

  .planning-table {
    overflow-x: scroll;
    padding: 55px 25px;
    background-color: $white;
    margin: 20px 0;
    border-radius: 10px;

    .table {
      display: grid;
      grid-template-columns: 60px repeat(7, minmax(140px, 200px));
      grid-template-rows: repeat(5, 100px);
      gap: 27px;

      .block {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 10px 10px 40px 0 rgba(#A6A6A6, $alpha: 0.25);
        border-radius: 10px;
        
        &.selected {
          color: #000000;
          background-color: rgba(#8AC923, $alpha: 0.34);
          border: 1px solid #8AC923;
        }
        
        &.big {
          padding: 30px;
          justify-content: flex-start;
          align-items: flex-start;
          grid-column: span 2;
          grid-row: span 3;
        }
      }

      .block-sp {
        display: flex;
        gap: 12px;
        flex-direction: column;

        .split {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 10px 10px 40px 0 rgba(#A6A6A6, $alpha: 0.25);
          border-radius: 10px;
          color: #F20000;
        }

        &.selected > .split {
          color: #000000;
          background-color: rgba(#8AC923, $alpha: 0.34);
          border: 1px solid #8AC923;
        }
      }
    }
  }
}

#conversation {
  .top-bar-back {
    display: flex;
    justify-content: flex-start;
    margin: 25px 15px;

    svg {
      cursor: pointer;
      height: 25px;
      width: 25px;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: translateX(-3px) rotateZ(-5deg);
      }
    }
  }

  .chat-box {
    width: 100%;

    .top-bar {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 25px 50px;
      background-color: $white;
      border-radius: 12px;
      box-shadow: 10px 10px 40px rgba(#C3C3C3, $alpha: 0.25);
      z-index: 1;

      .user {
        display: flex;
        align-items: center;
        gap: 13px;

        svg {
          width: 50px;
          height: 50px;
          color: #8AC923;
        }

        .user-name {
          display: flex;
          flex-direction: column;
          font-family: 'Poppins', sans-serif;
          align-self: flex-end;

          .name {
            font-weight: 500;
            font-size: 1.1rem;
          }

          .actif {
            font-size: 0.9rem;
            color: #969696;
          }
        }
      }

      .bar-action {
        display: flex;
        align-items: center;
        gap: 18px;

        svg {
          height: 30px;
          width: 30px;
        }
      }
    }

  }

  .zone {
    height: 690px;
    width: 100%;
    margin-top: -10px;
    background-color: #EFEFEF;
    border-radius: 12px;
    z-index: 0;
    
    
    .converssation {
      display: flex;
      flex-direction: column-reverse;
      padding: 34px;
      gap: 45px;
      max-height: calc(100% - 100px);
      overflow-y: auto;

      .disc {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;
        max-width: 100%;

        .date {
          white-space: nowrap;
        }

        &.left {
          flex-direction: row-reverse;
        }

        .bulle {
          max-width: 450px;
          padding: 30px 25px;
          background: $white;
          border-radius: 10px;
          box-shadow: 5px 5px 20px rgba(#BDBDBD, $alpha: 0.25);
        }

        .bulle-audio {
          display: flex;
          align-items: center;
          gap: 15px;
          max-width: 450px;
          padding: 30px 25px;
          background: $white;
          border-radius: 10px;
          box-shadow: 5px 5px 20px rgba(#BDBDBD, $alpha: 0.25);
          
          .start {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 38px;
            height: 38px;
            border-radius: 100px;
            background-color: $black;
            color: $white;
            aspect-ratio: 1/1;
            
            svg {
              height: 24px;
              width: 24px;
            }
          }
          
          .bar {
            max-width: 250px;
            min-width: 80px;
            width: 100vw;
            height: 7px;
            background-color: #F0F0F0;
            border-radius: 200px;
          }

          .time {
            color: #797979;
          }
        }
      }
    }

    .form {
      display: flex;
      justify-content: space-between;
      margin: 0 34px;
      background-color: $white;
      padding: 15px 20px;
      gap: 25px;
      
      select {
        padding: 15px 40px;
        box-shadow: 10px 10px 40px rgba(#C3C3C3, $alpha: 0.25);
        border: none;
        border-radius: 12px;
      }

      input {
        width: 100%;
        min-width: 150px;
        border: none;

        &:focus {
          outline: none;
        }
      }

      .nowrap {
        display: flex;
        gap: 10px;
        flex-wrap: nowrap;
      }

      .btnGroup {
        display: flex;
        align-items: center;
        gap: 25px;

        svg {
          cursor: pointer;
        }

        button {
          padding: 5px 25px;
          background-color: rgba($primary-color, $alpha: 0.5);
          color: $white;
          border: none;
          border-radius: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 780px) {
  #conversation {
    .chat-box {
      .top-bar {
        padding: 15px 25px;
      }

    }
    
    .zone {
      .converssation {
        padding: 16px;
        
        .disc {
          gap: 10px !important;
          
          .bulle {
            padding: 15px 12.5px;
          }

          .bulle-audio {
            padding: 15px 12.5px;
            max-width: 75% !important;
          }
        }
      }
      
      .form {
        flex-wrap: wrap;
        padding: 5px 10px;
        margin: 0 16px;
        border-radius: 5px;
        justify-content: center;

        select {
          padding: 7px 15px;
        }

        .btnGroup {
          gap: 10px;
        }
      }
    }
  } 
}

#expertStatistique {
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 320px));
    grid-auto-rows: 1fr;
    gap: 44px;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;

    .box {
      display: flex;
      flex-direction: column;
      gap: -10px;
      height: 100%;
      width: 100%;

      .top-bar {
        width: 100%;
        padding: 13px;
        background-color: rgba($primary-color, $alpha: 0.15);
        border: 0.5px solid $primary-color;
        text-align: center;
        border-radius: 7px;
        font-family: 'Poppins', sans-serif;
        font-size: 1.2rem;
        font-weight: 500;
      }

      .area {
        background-color: $white;
        text-align: center;
        height: 100%;
        padding: 16px;

        .title {
          font-family: 'Poppins', sans-serif;
          display: block;
          font-size: 2.5rem;
          margin: 50px 0;
        }

        .title-2 {
          font-family: 'Poppins', sans-serif;
          display: block;
          font-size: 1.75rem;
          margin: 15px 0;
        }

        .sub-title {
          font-family: 'Poppins', sans-serif;
          display: block;
          margin-top: 40px;
          color: rgba($color: #31346A, $alpha: 0.7);
        }

        .multi-tags {
          display: flex;
          justify-content: space-around;
          align-items: flex-start;
          
          .tag-container {
            display: flex;
            flex-direction: column;
            gap: 7px;

            .tag {
              padding: 5px 30px;
              background-color: rgba(#8AC923, $alpha: 0.3);
              border: 1px solid #8AC923;
              border-radius: 300px;
            }
          }
        }
      }
    }
  }

  .table-bar {
    margin-top: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: flex-end;

    input {
      border: 1px solid #ADADAD;
      background: none;
      padding: 5px 15px;
      border-radius: 5px;
    }

    select {
      border: 1px solid #ADADAD;
      background: none;
      padding: 5px 15px;
      border-radius: 5px;
    }
  }


  .tableContainer {
    margin-top: 50px;
    width: 100%;
    display: inherit;
    overflow: auto;
  }

  table {
    margin-top: 50px;
    background-color: $white;
    border-collapse: collapse;
    display: table;

    thead {
      border: 1.2px solid #D7D7D7;
      border-radius: 7px;
      tr > th {
        padding: 22px;
      }
    }

    tbody {
      tr > td {
        padding: 22px;
        border: 1.2px solid #D7D7D7;
        text-align: center;

        &.align-left {
          text-align: right;
        }
      }

      .badge {
        display: inline-block;
        margin-top: 5px;
        padding: 3px 15px;
        border-radius: 2px;
        background-color: rgba($color: #F20000, $alpha: 0.15);
        border: 0.7px solid #F20000;
        font-family: "Poppins", sans-serif;
      }

      .badge-green {
        display: inline-block;
        margin-top: 5px;
        padding: 3px 15px;
        border-radius: 2px;
        background-color: rgba($color: #81C313, $alpha: 0.15);
        border: 0.7px solid #81C313;
        font-family: "Poppins", sans-serif;
        margin-left: 10px;
      }

      .type {
        padding: 5px 18px; 
        border-radius: 5px;
        background-color: rgba($primary-color, $alpha: 0.1);
        font-family: "Poppins", sans-serif;
      }

      .color-green {
        color: #81C313;
      }

      .color-red {
        color: #F20000;
      }

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }  
}

@media screen and (max-width: 720px) {
  #expertStatistique {
    .container {
      grid-template-columns: repeat(auto-fill, 320px);
      justify-content: center;
    }
  }
}

// client
#profileClient {
  h2 {
    font-size: 2.5rem;

    &:after {
      display: block;
      content: ' ';
      position: absolute;
      height: 2px;
      width: 380px;
      background-color: rgba(#8AC923, $alpha: 0.5);
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 45px 60px;
    background-color: $white;
    margin: 42px 0;
    border-radius: 7px;
    border: 1.2px solid $gray-light;

    .ligne {
      display: flex;
      gap: 17px;

      span:nth-child(2) {
        color: #E37B7B;
      }
    }
  }  

  .btn-group {
    display: flex;
    gap: 18px;

    button {
      cursor: pointer;
      padding: 10px;
      border: none;
    
      &:nth-child(1) {
        background-color: rgba($primary-color, $alpha: 0.5);
        color: $white;
      }

      &:nth-child(2) {
        background-color: rgba(138, 201, 35, 0.5);
        color: $white;
      }
    }
  }
}

#expertListClient {
  h2 {
    font-size: 2.5rem;

    &:after {
      display: block;
      content: ' ';
      position: absolute;
      height: 2px;
      width: 380px;
      background-color: rgba(#8AC923, $alpha: 0.5);
    }
  }

  .expert-box {
    margin-top: 25px;
    padding: 30px 40px;
    background-color: $white;
    border: 2px solid $gray-light;
    border-radius: 7px;

    .title {
      color: #8AC923;
      font-size: 2.5rem;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
    }

    .desc {
      display: flex;
      align-items: center;
      gap: 35px;

      svg {
        height: 70px;
        width: 70px;
        color: #C3C3C3;
      }
    }

    .btn-group {
      display: flex;
      justify-content: flex-end;
      gap: 15px;
      flex-wrap: wrap;

      button {
        display: flex;
        gap: 7px;
        align-items: center;
        padding: 4px 22px;
        border: none;

        &:nth-child(2n+1) {
          background-color: rgba($primary-color, $alpha: 0.5);
          color: $white;
        }

        &:nth-child(2n) {
          background-color: rgba(#8AC923, $alpha: 0.5);
          color: $white;
        }
      }
    }
  }
}

#myConsultationsClient {
  h2 {
    font-size: 2.5rem;

    &:after {
      display: block;
      content: ' ';
      position: absolute;
      height: 2px;
      width: 380px;
      background-color: rgba(#8AC923, $alpha: 0.5);
    }
  }

  .tableContainer {
    margin-top: 50px;
    width: 100%;
    display: inherit;
    overflow: auto;
  }

  table {
    margin-top: 50px;
    background-color: $white;
    border-collapse: collapse;
    display: table;

    thead {
      border: 1.2px solid #D7D7D7;
      border-radius: 7px;
      tr > th {
        padding: 22px;
      }
    }

    tbody {
      tr > td {
        padding: 22px;
        border: 1.2px solid #D7D7D7;
        text-align: center;

        &.align-left {
          text-align: right;
        }
      }

      .badge {
        display: inline-block;
        margin-top: 5px;
        padding: 3px 15px;
        border-radius: 300px;
        background-color: rgba($color: #F20000, $alpha: 0.15);
        color: #B60000;
        font-family: "Poppins", sans-serif;
      }

      .type {
        padding: 5px 18px; 
        border-radius: 5px;
        background-color: rgba($primary-color, $alpha: 0.1);
        font-family: "Poppins", sans-serif;
      }

      .color-green {
        color: #81C313;
      }

      .color-red {
        color: #F20000;
      }

      .color-purple {
         color: $primary-color;
      }
    }
  }
}

#myPurchaseClient {
  h2 {
    font-size: 2.5rem;

    &:after {
      display: block;
      content: ' ';
      position: absolute;
      height: 2px;
      width: 380px;
      background-color: rgba(#8AC923, $alpha: 0.5);
    }
  }

  h3 {
    font-size: 1.563rem;
    color: #8AC923;
    font-family: 'Poppins', sans-serif;
    margin-top: 22px;
  }

  .tableContainer {
    margin-top: 50px;
    width: 100%;
    display: inherit;
    overflow: auto;
  }

  table {
    margin-top: 30px;
    background-color: $white;
    border-collapse: collapse;
    display: table;

    thead {
      border: 1.2px solid #D7D7D7;
      border-radius: 7px;
      tr > th {
        padding: 22px;
      }
    }

    tbody {
      tr > td {
        padding: 22px;
        border: 1.2px solid #D7D7D7;
        text-align: center;

        &.align-left {
          text-align: right;
        }
      }

      .badge {
        display: inline-block;
        margin-top: 5px;
        padding: 3px 15px;
        border-radius: 300px;
        background-color: rgba($color: #F20000, $alpha: 0.15);
        color: #B60000;
        font-family: "Poppins", sans-serif;
      }

      .type {
        padding: 5px 18px; 
        border-radius: 5px;
        background-color: rgba($primary-color, $alpha: 0.1);
        font-family: "Poppins", sans-serif;
      }

      .color-green {
        color: #81C313;
      }

      .color-red {
        color: #F20000;
      }

      .color-purple {
         color: $primary-color;
      }
    }
  }
}

#payementClient {
  h2 {
    font-size: 2.5rem;

    &:after {
      display: block;
      content: ' ';
      position: absolute;
      height: 2px;
      width: 380px;
      background-color: rgba(#8AC923, $alpha: 0.5);
    }
  }

  .form-control {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    label {
      font-family: 'Poppins', sans-serif;
      color: #31346A;
      margin-bottom: 21px;
    }

    select {
      padding: 10px 25px;
      border-radius: 5px;
      border: 2px solid $gray-light;
      color: #8C8C8C;
    }
  }

  .split-line {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;
    color: #31346A;
    font-family: 'Poppins', sans-serif;
  }

  .form-control {

    .input-control {
      display: flex;

      .icon {
        padding: 12px 16px;
        background-color: #E7E7E7;
        margin-right: -10px;
        z-index: 1;
        border-radius: 5px 0 0 5px;
      }

      input {
        width: 100%;
      }

      button {
        cursor: pointer;
        padding: 12px 55px;
        margin-left: -10px;
        border: none;
        background-color: #B284FE;
        color: $white;
        border-radius: 0 5px 5px 0;
      }
    }
  }

  hr {
    border-top: 2px solid rgba(#8AC923, 0.5);
  }

  .box {
    margin-top: 20px;
    background-color: #F4F4F4;
    padding: 7px 20px;
    border-radius: 5px;
  
    .form {
      display: flex;
      gap: 5px;
    }

    &.selected {
      border: 2px solid $gray-light;
    }

    form {
      .form-control {
        label {
          color: #31346A;
          margin-bottom: 3px;
        }
        
        input {
          padding: 14px 22px;
          border: 2px solid $gray-light;
        }
      }

      .check-form {
        display: flex;
        margin-top: 5px;
        display: flex;
        gap: 5px;

        label {
          color: #31346A;
          font-family: 'Poppins', sans-serif;
        }
      }

      .col-2-button {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
          padding: 6px 52px;
          background: rgba(138, 201, 35, 0.5);
          border: none;
          color: $white;
          font-family: 'Poppins';
          margin-top: 5px;
        }
      }

      .col-2 {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;

        .form-control {
          flex: 1;
        }
      }
    }
  }
}

#myMeetingClient {
  h2 {
    font-size: 2.5rem;

    &:after {
      display: block;
      content: ' ';
      position: absolute;
      height: 2px;
      width: 380px;
      background-color: rgba(#8AC923, $alpha: 0.5);
    }
  }

  .tableContainer {
    margin-top: 50px;
    width: 100%;
    display: inherit;
    overflow: auto;
  }

  table {
    margin-top: 50px;
    background-color: $white;
    border-collapse: collapse;
    display: table;

    thead {
      border: 1.2px solid #D7D7D7;
      border-radius: 7px;
      tr > th {
        padding: 22px;
      }
    }

    tbody {
      tr > td {
        padding: 22px;
        border: 1.2px solid #D7D7D7;
        text-align: center;

        &.align-left {
          text-align: right;
        }
      }

      .badge {
        display: inline-block;
        margin-top: 5px;
        padding: 3px 15px;
        border-radius: 300px;
        background-color: rgba($color: #F20000, $alpha: 0.15);
        color: #B60000;
        font-family: "Poppins", sans-serif;
      }

      .type {
        padding: 5px 18px; 
        border-radius: 5px;
        background-color: rgba($primary-color, $alpha: 0.1);
        font-family: "Poppins", sans-serif;
      }

      .color-green {
        color: #81C313;
      }

      .color-red {
        color: #F20000;
      }

      .color-purple {
         color: $primary-color;
      }
    }
  }
}

#myMethodsPayementClient {
  h2 {
    font-size: 2.5rem;

    &:after {
      display: block;
      content: ' ';
      position: absolute;
      height: 2px;
      width: 380px;
      background-color: rgba(#8AC923, $alpha: 0.5);
    }
  }

  .tableContainer {
    width: 100%;
    display: inherit;
    overflow: auto;
  }

  table {
    margin-top: 40px;
    background-color: $white;
    border-collapse: collapse;
    display: table;

    thead {
      border: 1.2px solid #D7D7D7;
      border-radius: 7px;
      tr > th {
        padding: 22px;
      }
    }

    tbody {
      tr > td {
        padding: 22px;
        border: 1.2px solid #D7D7D7;
        text-align: center;

        &.align-left {
          text-align: right;
        }
      }

      .badge {
        display: inline-block;
        margin-top: 5px;
        padding: 3px 15px;
        border-radius: 300px;
        background-color: rgba($color: #F20000, $alpha: 0.15);
        color: #B60000;
        font-family: "Poppins", sans-serif;
      }

      .type {
        padding: 5px 18px; 
        border-radius: 5px;
        background-color: rgba($primary-color, $alpha: 0.1);
        font-family: "Poppins", sans-serif;
      }

      .color-green {
        color: #81C313;
      }

      .color-red {
        color: #F20000;
      }

      .color-purple {
         color: $primary-color;
      }
    }
  }

  .btn-group {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;

    button {
      cursor: pointer;
      padding: 8px 30px;
      background-color: rgba($primary-color, $alpha: 0.5);
      border: none;
      color: $white;
    }
  }

  .box {
    padding: 40px 70px;
    background-color: $white;
    border: 1px solid $gray-light;

    .title {
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
      font-size: 2.5rem;
      color: #31346A;
    }

    .input-container {
      display: flex;
      width: 100%;
      margin-bottom: 15px;
   
      .icon {
        background-color: #DBDBDB;
        padding: 10px 25px;
        margin-right: -10px;
        z-index: 1;
        border-radius: 5px;
      }

      input {
        width: 100%;
        padding: 10px 20px 10px 30px;
      }
    }

    label {
      columns: #1E1E1E;
      font-family: 'Poppins', sans-serif;
    }

    .col-2 {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;

      .form-group {
        flex: 1;
      }
    }

    .col-flex {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 20px;

      .form-group {
        flex: 1;
      }

      span {
        font-family: 'Poppins', sans-serif;
      }

      svg {
        height: 30px;
        width: auto;
      }
    }    
  }

  .btn {
    cursor: pointer;
    background-color: rgba(138, 201, 35, 0.5);
    padding: 7px 50px;
    margin-top: 20px;
    border: none;
    color: $white;
    font-family: 'Poppins', sans-serif;
  }
}

// login page
#loginPage {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  min-height: 100vh;
  overflow: hidden;

  .content {
    padding: 40px 150px;
    width: 100%;
    
    img {
      display: block;
      margin: 15px auto;
    }

    h2 {
      text-align: center;
      font-weight: 600;
    }

    .sub-title {
      display: block;
      margin: 0 auto 70px;
      text-align: center;
      color: #8AC923;
    }

    form {
      .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;

        label {
          margin-bottom: 17px;
        }

        input {
          padding: 16px 33px;
          border: 0.5px solid $black;
          border-radius: 10px;
        }

      }

      .submit {
        cursor: pointer;
        padding: 18px 20px;
        background: $primary-dark-color;
        color: $white;
        width: 100%;
        border: none;
        border-radius: 10px;
        font-size: 1.2rem;
        font-weight: 600;
      }
    }

    .nouveauClient {
      display: block;
      margin: 47px 0;
    }

    .btn {
      cursor: pointer;
      display: block;
      padding: 15px 70px;
      background-color: $primary-dark-color;
      color: $white;
      width: min-content;
      border-radius: 10px;
      text-decoration: none;
      font-weight: 600;
      margin-bottom: 34px;
    }

    .link {
      cursor: pointer;
    }
  }

  .bg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../public/assets/bg.png");
    background-size: cover;
    border-radius: 100px;
    width: 120%;
    gap: 20px;

    img {
      height: 66px;
    }
  }
}
@media screen and (max-width: 920px) {
  #loginPage {
    grid-template-columns: 1fr;
    
    .content {
      padding: 40px 60px;
    }

    .bg {display: none;}
  }
}

// register Page
#registerPage {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  min-height: 100vh;
  overflow: hidden;

  .content {
    padding: 40px 150px;
    width: 100%;
    
    .logo {
      display: block;
      margin: 15px auto;
    }

    h2 {
      text-align: center;
      font-weight: 600;
    }

    .sub-title {
      display: block;
      margin: 0 auto 70px;
      text-align: center;
      color: #8AC923;
    }

    form {
      .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;

        label {
          margin-bottom: 17px;
        }

        input {
          padding: 16px 33px;
          border: 0.5px solid $black;
          border-radius: 10px;
        }

        .PhoneInputCountry {
          background-color: #815AC1;
          padding: 16px 14px;
          border-radius: 10px;
          margin-right: -15px;
          
          input {
            z-index: -1;
          }
        }

      }

      .submit {
        cursor: pointer;
        padding: 18px 20px;
        background: #815AC1;
        color: $white;
        width: 100%;
        border: none;
        border-radius: 10px;
        font-size: 1.2rem;
        font-weight: 600;
      }

      .checkbox {
        display: flex;
        margin-bottom: 25px;
        align-items: center;
        gap: 13px;

        input {
          appearance: none;
          background-color: $white;
          margin: 0;
          font: inherit;
          color: currentColor;
          width: 21px;
          height: 21px;
          border: 0.5px solid $black;
          border-radius: 2px;
          transform: translateY(-0.075em);
          display: grid;
          place-content: center;

          &::before {
            content: "";
            width: 12px;
            height: 12px;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $primary-color;
            transform-origin: bottom left;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%)
          }

          &:checked::before {
            transform: scale(1);
          }
        }
      }
    }

    .nouveauClient {
      display: block;
      margin: 47px 0;
    }

    .btn {
      cursor: pointer;
      display: block;
      padding: 15px 70px;
      background-color: $primary-dark-color;
      color: $white;
      width: min-content;
      border-radius: 10px;
      text-decoration: none;
      font-weight: 600;
      margin-bottom: 34px;
    }

    .link {
      cursor: pointer;
    }
  }

  .bg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../public/assets/bg.png");
    background-size: cover;
    border-radius: 100px;
    width: 120%;
    gap: 20px;

    img {
      height: 66px;
    }
  }
}

@media screen and (max-width: 920px) {
  #registerPage {
    grid-template-columns: 1fr;
    
    .content {
      padding: 40px 60px;
    }

    .bg {display: none;}
  }
}

// password reset
#passwordReset {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  min-height: 100vh;
  overflow: hidden;

  .content {
    transform: translateY(20%);
    padding: 40px 150px;
    width: 100%;
    
    img {
      display: block;
      margin: 15px auto 70px;
    }

    form {
      .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;

        label {
          margin-bottom: 17px;
        }

        input {
          padding: 16px 33px;
          border: 0.5px solid $black;
          border-radius: 10px;
        }

      }

      .submit {
        cursor: pointer;
        padding: 18px 20px;
        background: $primary-dark-color;
        color: $white;
        width: 100%;
        border: none;
        border-radius: 10px;
        font-size: 1.1rem;
        font-weight: 600;
        white-space: break-spaces;
      }
    }

    .nouveauClient {
      display: block;
      margin: 47px 0;
    }

    .btn {
      cursor: pointer;
      display: block;
      padding: 15px 70px;
      background-color: $primary-dark-color;
      color: $white;
      width: min-content;
      border-radius: 10px;
      text-decoration: none;
      font-weight: 600;
      margin-bottom: 34px;
    }

    .link {
      cursor: pointer;
    }
  }

  .bg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../public/assets/bg.png");
    background-size: cover;
    border-radius: 100px;
    width: 120%;
    gap: 20px;

    img {
      height: 66px;
    }
  }
}

@media screen and (max-width: 920px) {
  #passwordReset {
    grid-template-columns: 1fr;
    
    .content {
      padding: 40px 60px;
    }

    .bg {display: none;}
  }
}

// forfaits Path
#forfaitsPath {
  padding: 75px 100px;

  .container {
    margin: 115px 40px 30px;


    h2 {
      margin-bottom: 50px;
      color: #31346A;
      font-weight: 600;
    }

    .blocks {
      display: grid;
      grid-template-columns: repeat(auto-fill, 315px);
      gap: 70px;
    
      .block {
        max-width: 315px; 
        border-radius: 7px;
        overflow: hidden;
        box-shadow: 10px 10px 40px 0 rgba($color: #AEAEAE, $alpha: 0.25);

        .preview {
          position: relative;
          background-image: url('../public/assets/preview.png');
          background-repeat: no-repeat;
          background-size: cover;
          height: 209px;
          border-radius: 7px;
          overflow: hidden;

          .text {
            position: absolute;
            bottom: 0;
            padding: 9px;
            text-align: center;
            width: 100%;
            background-color: rgba($black, $alpha: 0.5);
            color: $white;
            font-weight: 600;
          }
        }

        .forfait {
          display: block;
          margin: 30px 45px;
          text-align: center;
        }

        .price {
          display: block;
          text-align: center;
          color: #D00000;
          font-family: 'Poppins', sans-serif;
          font-size: 1.875rem;
          width: 100%;
          padding: 10px;

          .text {
            color: $black;
            font-size: 1rem;
          }
        }

        .btn {
          display: block;
          padding: 13px 40px;
          margin: 40px auto;
          border: 1.2px solid #772CF3;
          background-color: rgba($color: #772CF3, $alpha: 0.15);
          border-radius: 7px;
          color: #772CF3;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
        }

        .list {
          display: grid;
          grid-template-columns: 19px 1fr;
          gap: 5px;
          padding: 30px 40px;

          svg {
            height: 19px;
            width: 19px;
          }
        }

        .payement {
          padding: 14px 44px;
          background-color: rgba(#772CF3, $alpha: 0.15);
        
          svg {
            margin-right: 35px;
          }
        }

        ul {
          padding: 23px 50px;
          li {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 5px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  #forfaitsPath {
    padding: 40px 60px;
    
    .container {
      margin: 75px 0px 30px;
    }
  }
}

// nos experts
#nosExperts {
  padding: 75px 100px;

  .container {
    margin-top: 100px;

    h2 {
      margin-bottom: 50px;
      color: #8AC923;
      font-weight: 600;
    }

    .tags {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
      margin-bottom: 15px;
      
      span {
        cursor: pointer;
        padding: 7px 15px;
        color: #2E59DF;
        border: 1.2px solid #2E59DF;
        background-color: rgba($color: #2E59DF, $alpha: 0.15);
        border-radius: 7px;
        transition: all .3s ease-in-out;

        &:hover {
          transform: translateY(-4px);
        }
      }
    }

    .blocks {
      display: grid;
      grid-template-columns: repeat(auto-fill, 315px);
      gap: 70px;
    
      .block {
        max-width: 315px; 
        border-radius: 7px;
        overflow: hidden;
        box-shadow: 10px 10px 40px 0 rgba($color: #AEAEAE, $alpha: 0.25);
      
        .preview {
          position: relative;
          background-image: url('../public/assets/preview.png');
          background-repeat: no-repeat;
          background-size: cover;
          height: 209px;
          border-radius: 7px;

          .text {
            position: absolute;
            bottom: 0;
            text-align: center;
            width: 100%;
            height: 55px;
            background-color: rgba($black, $alpha: 0.5);
          
            .avatar {
              transform: translateX(-50%);
              left: 50%;
              position: absolute;
              height: 119px;
              width: 119px;
              background-color: #8B8B8B;
              border-radius: 50%;
              border: 4px solid $white;
            }
          }

        }

        .top-bar {
          display: flex;
          justify-content: space-between;
          padding: 11px 20px;
          
          span {
            font-size: 0.5rem;
            color: #8AC923;
          }
          
          .icons {
            display: flex;
            gap: 2px;

            svg {
              cursor: pointer;
              padding: 2px;
              background-color: #5A5A5A;
              border-radius: 50%;
              transition: all 0.3s ease-in-out;

              &:hover {
                transform: scale(1.1);
              }
            }

            svg:last-child {
              background-color: #3EA336;
            }
          }
        }

        .title {
          text-align: center;
          color: $primary-color;
          margin-top: 30px;
        }

        .stars {
          text-align: center;
          margin-top: 7px;
        }

        p {
          text-align: center;
          margin: 15px 40px 0px;
        }

        .btn {
          margin: 25px auto 40px;
          text-align: center;
          
          button {
            cursor: pointer;
            background-color: #2E59DF;
            color: $white;
            padding: 7px 27px;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 1.1rem;
            border: none;
            border-radius: 30px;

            &.disable {
              cursor: not-allowed;
              border-radius: 7px;
              background-color: $gray;
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: 920px) {
  #nosExperts {
    padding: 40px 60px;
    
    .container {
      margin: 75px 0px 30px;
    }
  }
}

// profile path
#profilePath {
  padding: 75px 100px;

  .back-btn {
    display: block;
    margin-top: 25px;

    svg {
      height: 23px;
      width: 23px;
    }
  }
  
  .container {
    margin: 90px 40px 30px;

    #profileBar {
      display: grid;
      gap: 75px;
      grid-template-columns: 300px 1fr;
      align-items: flex-start;

      .picture {
        height: 300px;
        width: 300px;
        background-color: #8B8B8B;
        border-radius: 50%;
        border: 4px solid $white;
        box-shadow: 10px 10px 40px 0 rgba(#555555, $alpha: 0.25);
      }
    
      .info {
        display: flex;
        flex-direction: column;
        gap: 50px;
        
        .details {
          display: flex;
          flex-wrap: wrap;
          gap: 50px;

          h3 {
            font-size: 4.375rem;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
          }

          button {
            color: $white;
            background-color: #2E59DF;
            border-radius: 30px;
            border: none;
            padding: 9px 22px;
            margin-top: 25px;
          }

          .avis {
            display: flex;
            flex-direction: column;
            gap: 15px;
            justify-content: flex-end;
          }

          span {
            color: #606060;
            font-size: 1.05rem;
            font-family: 'Poppins', sans-serif;
          }

          .stars {
            color: #F5CE00;
          }
        }

        .nav {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 30px;

          div {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 20px;
            font-size: 1.2rem;

            svg {
              height: 40px;
              width: 40px;
              color: #938181;
            }
          }
        }
      }
    }

    .col2-1 {
      display: grid;
      grid-template-columns: 6fr 4fr;
      gap: 35px;
      
      > div {
        border: 1.2px solid #D7D7D7;
        border-radius: 7px;
        
        &:nth-child(1) {
          padding: 45px 80px;
          color: rgba($color: #606060, $alpha: 0.5);
          font-size: 2rem;
          font-family: 'Poppins', sans-serif;
        }
  
        &:nth-child(2) {
          padding: 120px 25px;

          .title {
            display: block;
            font-size: 2rem;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            color: #000000;
            text-align: center;
            margin-bottom: 75px;
          }

          .bar {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 15px 30px;
            padding: 25px 55px;
            gap: 25px;
            color: $white;
            font-family: 'Poppins', sans-serif;
            border-radius: 7px;
            background:#474549;
            transition: all .3s ease-in-out;

            &:hover {
              transform: translateY(-5px);
            }

            &.green {
              background: #69ae79;
            }
          }

        }
      }
    }

    .col2-2 {
      display: grid;
      grid-template-columns: 6fr 4fr;
      gap: 35px;
      
      > div {
        border: 1.2px solid #D7D7D7;
        border-radius: 7px;
        padding: 45px 50px;
        
        &:nth-child(1) {
          display: flex;
          flex-wrap: wrap;
          gap: 50px;
          justify-content: space-between;

          .block {
            padding: 45px 0;
            border-radius: 20px;
            box-shadow: 10px 10px 40px 0 rgba($color: #CFCFCF, $alpha: 0.25);
            max-width: 293px;
            min-width: 250px;

            .top-bar {
              display: block;
              text-align: center;
              background-color: rgba($color: $primary-color, $alpha: 0.25);
              width: 100%;
              padding: 22px;
            }

            .list {
              display: flex;
              justify-content: center;
              text-align: center;
              margin: 44px auto;
              gap: 10px;

              svg {
                color: #3EA336;
              }
            }

            .footer-bar {
              cursor: pointer;
              display: block;
              text-align: center;
              border: 2px solid rgba(119, 44, 243, 0.5);
              padding: 10px;
              margin: 0 20px;
              border-radius: 7px;
              color: rgba(119, 44, 243, 0.5);
              transition: all .3s ease-in-out;

              &:hover {
                background-color: rgba(119, 44, 243, 0.5);
                color: $white;
              }
            }
          }
        }
  
        &:nth-child(2) {

          h3 {
            font-size: 1.7rem;
            font-family: 'Poppins', sans-serif;
            margin-bottom: 12px;
          }

          .ligne {
            box-shadow: 10px 10px 40px 0 rgba($color: #AEAEAE, $alpha: 0.25);
            padding: 9px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 7px;
            margin-bottom: 25px;

            span {
              display: block;
            }

            > div:nth-child(1) {
              color: #F25858;
            }
          }
          
        }
      }
    }

    .col2-3 {
      display: grid;
      grid-template-columns: 1fr;
      gap: 35px;

      .box {
        border: 1.2px solid #D7D7D7;
        border-radius: 7px;
        padding: 45px 80px;

        .title {
          display: block;
          font-size: 2rem;
          color: #5A5A5A;
          font-family: 'Poppins', sans-serif;
        }
        
        .badge {
          display: inline-block;
          margin-top: 5px;
          padding: 7px 32px;
          color: #F20000;
          background-color: rgba($color: #F20000, $alpha: 0.15);
          border-radius: 300px;
          width: min-content;
          margin: 50px 0;
        }

        .stars-box {
          display: flex;
          gap: 2px;
          color: #5A5A5A;

          .stars {
            color: #F5CE00;
          }
        }

        .reponsse {
          background-color: #E8E8E8;
          color: #5A5A5A;
          padding: 15px;
          margin: 15px;

          .title_res {
            font-size: 1.05rem;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  #profilePath {
    padding: 50px;

    #profileBar{
      grid-template-columns: 150px 1rem !important;
      gap: 15px !important;

      .picture {
        width: 150px !important;
        height: 150px !important;
      }

      .info .details {
        h3 {
          font-size: 1.5rem !important;
        }
      }

      .nav {
        span {
          font-size: 1rem !important;
        }

        svg {
          height: 25px !important;
          width: 25px !important;
        }
      }
    }

    .navLink {
      font-size: 1.2rem;
      gap: 25px !important;
      
      > div.selected {
        border-width: 2px;
      }
    }

    .col2-1, .col2-2, .col2-3 {
      grid-template-columns: 6fr !important;
    }
  }
}

@media screen and (max-width: 720px) {
  #profilePath {
    padding: 15px;
  
    .col2-1 > div {
      padding: 30px 40px !important;
      border: 1px solid red;

      .bar {
        padding: 15px 20px !important;
      }
    }

    .col2-2 > div {
      padding: 30px 40px !important;

      .block {
        min-width: 220px !important;
      }
    }

    .col2-3 > div {
      padding: 30px 30px !important;
    }
  }
}

.phone-number-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.country-selector {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.country-selector select {
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  margin-right: 8px;
}

.country-selector img {
  width: 20px;
  margin-left: 8px;
}

.phone-input-wrapper {
  width: 100%;
}

.phone-input-wrapper input {
  cursor: pointer;
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
  margin-left: -20px;
  height: 100%;
  width: calc(100% + 20px);
}

.country-display {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 19px 14px;
  border-radius: 10px;
  background-color: $primary-color;
  height: 100%;
  z-index: 1;
}

.country-display img {
  width: 20px;
  margin-right: 8px;
  border-radius: 2px;
}

.country-display span {
  font-size: 14px;
  color: $white;
  white-space: nowrap;
}

.phone-number-input select {
  position: absolute;
  transform: translateY(150px);
  max-height: 250px;
  z-index: 3;
}

.country-dropdown {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 8px;
  max-height: 200px;
  overflow-y: auto;
  max-width: 400px;
  width: 60%;
  z-index: 1;
  transform: translateY(60%);
}

.country-dropdown-item {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  cursor: pointer;
}

.country-dropdown-item:hover {
  background-color: #f0f0f0;
}

.country-dropdown-item img {
  width: 20px;
  margin-right: 8px;
}

.country-dropdown-item span {
  font-size: 14px;
}

.custom-dropdown {
  position: relative;
  font-family: Arial, sans-serif;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  cursor: pointer;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: none;
  min-width: max-content;
}

.dropdown-list.open {
  display: block;
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
  min-width: max-content;
}

.dropdown-list li:hover {
  background-color: #f2f2f2;
}

.arrow {
  font-size: 10px;
  margin-left: 5px;
  transition: all .3s ease-in-out;
}

.arrow.open {
  transform: rotate(180deg);
}
